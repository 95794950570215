// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1 1;
    width: 100%;
    height: 100vh;
}

.logo {
    /*width: 400px;
    height: 90px;*/
    margin-top: 200px;
}

.formLogin {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.rememberPasswordLogin {
    margin-top: 30px;
    font-weight: 600;
    font-size: 13px;
    color: rgba(89, 89, 89, 0.9);
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/views/auth/login/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,QAAM;IACN,WAAW;IACX,aAAa;AACjB;;AAEA;IACI;kBACc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,4BAA4B;IAC5B,eAAe;AACnB","sourcesContent":[".containerLogin {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    flex:1;\n    width: 100%;\n    height: 100vh;\n}\n\n.logo {\n    /*width: 400px;\n    height: 90px;*/\n    margin-top: 200px;\n}\n\n.formLogin {\n    display: flex;\n    flex-direction: column;\n    width: 400px;\n}\n\n.rememberPasswordLogin {\n    margin-top: 30px;\n    font-weight: 600;\n    font-size: 13px;\n    color: rgba(89, 89, 89, 0.9);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
