import React, {useState, useEffect} from 'react'
import './sessions.css'

import Session from '../../components/session/session.component'

import { getSessions, updateSession, getCloseSessionsCoach } from '../../services/data.service'

const Sessions = () => {
    const [sessions, setSessions] = useState()
    const [closeSessions, setCloseSessions] = useState()

    useEffect(() => {
        getSessions().then(res => {
            console.log('estas son las sesiones', res.data)
            setSessions(res.data)
        }).catch(err => {
            console.log('error recogiendo las sesiones', err)
        })

        getCloseSessions()
    }, [])

    const enterSession = async (sessionId, uuid) => {
        console.log('this is a session id', sessionId)
        let userStorage = await localStorage.getItem('USER');
        let user = JSON.parse(userStorage).user
        let token = await localStorage.getItem('token')
        updateSession(user.id, user.name + ' ' + user.lastname, user.image, sessionId).then(res => {
            const a = document.createElement("a")
            a.href = `https://videochat.mybeatcoach.com/sfu/${uuid}/${token}/${sessionId}`
            a.target = "_blank"
            a.rel = "noopener"
            a.click()
            console.log('Sesion actualizada correctamente', res)
        }).catch(err => {
            console.log('Error actualizando la sesion', err)
        })
    }

    const getCloseSessions = async () => {
        let userStorage = await localStorage.getItem('USER');
        let user = JSON.parse(userStorage).user
        getCloseSessionsCoach(user.id).then(res => {
            console.log('close sessions', res.data)
            setCloseSessions(res.data)
        }).catch(err => {
            console.log('Error recogiendo las sesiones')
        })
    }

    const fifteenMinutesReaming = (startDate) => {
        let currentDate = new Date()
        let timeLeft = new Date(startDate) - currentDate;
        let fifteenMinutes = 15 * 60 * 1000;
        if (timeLeft <= fifteenMinutes) {
          return true
        } else {
          return false
        }
      }

    return (
        (sessions&& closeSessions) &&
        <div className="containerSessions">
                <p className='titleSessions'>Sesiones</p>

                <div className="containerColumns">
                    <div className="column">
                        <p className="titleColumn">Sesiones pendientes</p>
                        {sessions.map(session => {
                            return (
                                <Session
                                    image={session.user_image}
                                    name={session.user_name}
                                    startDate={session.start_date}
                                    endDate={session.end_date}
                                    press={() => enterSession(session.id, session.videocall_uuid)}
                                />
                            )
                        })
                        }
                    </div>
                    <div className="column">
                        <p className="titleColumn">Histórico de sesiones</p>
                        {closeSessions.map(session => {
                            return (
                                <Session
                                    image={session.user_image}
                                    name={session.user_name}
                                    startDate={session.start_date}
                                    endDate={session.end_date}
                                    press={() => {
                                        alert('Esta videollamada ya ha caducado.')
                                    }}
                                />
                            )
                        })
                        }
                    </div>
                </div>
        </div>
    )
}

export default Sessions