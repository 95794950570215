import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {message} from 'antd';

import './rating.css';

import { Rate } from 'antd';

import {rateSession} from '../../services/chat.service';
import {getSpecialities} from '../../services/data.service';

import routeStore from '../../store/routeStore';
import Button from '../../components/button/button.component';
import RadioButton from '../../components/radioButton/radioButton.component';

const Rating = () => {
    const [rate, setRate] = useState(null);
    const [speciality, setSpeciality] = useState(null);
    const [allSpecialities, setAllSpecialities] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const setRoute = routeStore(state => state.setRoute);

    console.log('Location rate', location)

    useEffect(() => {
        // Guarda esta ruta para saber que hay una valoracion pendiente
        setRoute(location.pathname);

        // Recoge las especialidades para mostrarlas  y poder seleccionarlas
        getSpecialities().then(res => {
            console.log('Especialidades recogidas con exito', res.data);
            setAllSpecialities(res.data);
        }).catch(err => {
            console.log('ERROR al recoger las especialidades', err);
        })
    }, [])

    // Envia al servidor la valoracion y la especialidad seleccionada
    const ratingSession = () => {
        console.log('SPECIALITY', rate)
        if (rate == null) {
            message.error('Valora el funcionamiento del workplace para continuar.');
        } else {
            if (speciality == null) {
                message.error('Selecciona una especialidad para continuar.');
            } else {
                rateSession(location.state.idChat, rate, speciality).then(res => {
                    console.log('Sesion valorada con exito', res);
                    setRoute(null);
                    navigate('/chat')
                }).catch(err => {
                    console.log('ERROR al valorar la sesion', err);
                })
            }
        }
    }

    return (
        <div className={'containerRating'}>
            <p className={'titleRating'}>Valoración del funcionamiento del Workplace</p>

            <Rate
                className={'rate'}
                allowHalf={false}
                defaultValue={0}
                onChange={value => setRate(value)}
            />

            <p className={'textConfirmRating'}>Indica la especialidad de la conversación</p>

            <div className={'containerRadioButtons'}>
                <div className={'blockRadioButtons'}>
                    {allSpecialities.map((speciality, index) => {
                        if (index % 2 === 0) {
                            return (
                                <RadioButton name={'radioRate'} id={speciality.id} text={speciality.name} changeValue={value => setSpeciality(value)}/>
                            )
                        }
                    })
                    }
                </div>
                <div className={'blockRadioButtons blockRadioButtonsRight'}>
                    {allSpecialities.map((speciality, index) => {
                        if (index % 2 !== 0) {
                            return (
                                <RadioButton name={'radioRate'} id={speciality.id} text={speciality.name} changeValue={value => setSpeciality(value)}/>
                            )
                        }
                    })
                    }
                </div>
            </div>

            <div className={'containerButtonRating'}>
                <Button
                    text={'Enviar'}
                    press={() => ratingSession()}
                />
            </div>
        </div>
    )
}

export default Rating;
