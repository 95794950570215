import React from 'react';

import './menu.css';
import {IconAssets} from '../../utils/ImageAssets';

import {useNavigate} from 'react-router-dom';

import DotQuantity from '../dotQuantity/dotQuantity.component';

import { userEndWriting } from '../../services/chat.service';

// Muestra el componente del menu dentro del navbar
const Menu = ({route, unreadMessages}) => {
    const navigate = useNavigate();

    const chatEndWriting = () => {
        let chat = localStorage.getItem('chatSelected')
        if (chat != null && chat != undefined && chat != 'null' && chat != '') {
            userEndWriting(JSON.parse(chat).chatId, JSON.parse(chat).userId).then(res => {
                console.log('Se ha dejado de escribir en el chat')
                localStorage.setItem('chatSelected', null)
            }).catch(err => {
                localStorage.setItem('chatSelected', null)
                console.log('No se ha podido dejar de escribir en el chat')
            })
        }
        console.log('end', localStorage.getItem('chatSelected'))
    }

    // Segun la ruta que venga por paremetros se muestran los items del menu de una manera u otra
    return (
        <div className={'containerMenu'}>
            <div className={route === '/users' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/users')
                }}>
                <img className={'iconMenuItem'} src={route === '/users' ? IconAssets.profileFocus : IconAssets.profileNoFocus}/>
                <p className={route === '/users' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Usuarios</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/users'}/>
            </div>
            
            <div className={(route === '/' || route === '/chat') ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                chatEndWriting()
                navigate('/chat')
                }}>
                <img className={'iconMenuItem'} src={(route === '/' || route === '/chat') ? IconAssets.messagesFocus : IconAssets.messagesNoFocus}/>
                <p className={route === '/' || route === '/chat' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Mensajes</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={unreadMessages} focus={route === '/' || route === '/chat'}/>
            </div>

            <div className={route === '/sessions' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                chatEndWriting()
                navigate('/sessions')
                }}>
                <img className={'iconMenuItem'} src={route === '/sessions' ? IconAssets.sessionsFocus : IconAssets.sessionsNoFocus}/>
                <p className={route === '/sessions' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Sesiones</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/sessions'}/>
            </div>

            <div className={route === '/diary' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                chatEndWriting()
                navigate('/diary')
                }}>
                <img className={'iconMenuItem'} src={route === '/diary' ? IconAssets.agendaFocus : IconAssets.agendaNoFocus}/>
                <p className={route === '/diary' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Agenda</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/diary'}/>
            </div>


            <div className={route === '/history' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                chatEndWriting()
                navigate('/history')
                }}>
                <img className={'iconMenuItem'} src={route === '/history' ? IconAssets.historyFocus : IconAssets.historyNoFocus}/>
                <p className={route === '/history' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Historial de mensajes</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/history'}/>
            </div>


            <div className={route === '/articles' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                chatEndWriting()
                navigate('/articles')
                }}>
                <img className={'iconMenuItem'} src={route === '/articles' ? IconAssets.articlesFocus : IconAssets.articlesNoFocus}/>
                <p className={route === '/articles' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Artículos</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/articles'}/>
            </div>


            <div className={route === '/profile' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                chatEndWriting()
                navigate('/profile')
                }}>
                <img className={'iconMenuItem'} src={route === '/profile' ? IconAssets.profileFocus : IconAssets.profileNoFocus}/>
                <p className={route === '/profile' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Perfil</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/profile'}/>
            </div>

        </div>
    )
}

export default Menu;
