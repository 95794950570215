// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.realContainerInput {
    margin-top: 40px;
    text-align: left;
}

.labelInput {
    margin-left: 7px;
    margin-right: auto;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
}

.containerInput {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 356px;
    height: 53px;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.input {
    flex: 1 1;
    border: none;
    outline: none;
    margin-left: 23px;
}

.input:focus {
    border: none
}


`, "",{"version":3,"sources":["webpack://./src/components/input/input.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,8CAA8C;IAC9C,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,SAAO;IACP,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI;AACJ","sourcesContent":[".realContainerInput {\n    margin-top: 40px;\n    text-align: left;\n}\n\n.labelInput {\n    margin-left: 7px;\n    margin-right: auto;\n    margin-bottom: 16px;\n    font-weight: 400;\n    font-size: 15px;\n}\n\n.containerInput {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    width: 356px;\n    height: 53px;\n    padding-left: 22px;\n    padding-right: 22px;\n    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);\n    border-radius: 20px;\n    overflow: hidden;\n    background-color: #FFFFFF;\n}\n\n.input {\n    flex: 1;\n    border: none;\n    outline: none;\n    margin-left: 23px;\n}\n\n.input:focus {\n    border: none\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
