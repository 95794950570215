// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerNotFound {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #0F1841;
}

.containerContentNotFound {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleNotFound {
    margin: 0;
    font-weight: 700;
    font-size: 2.4vw;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.9);
}

.subtitleNotFound {
    margin: 1.5vw 0 2.1vw 0;
    font-weight: 600;
    font-size: 0.89vw;
    text-align: center;
    letter-spacing: 0.75px;
    color: rgba(255, 255, 255, 0.75);
}

.buttonNotFound {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 1.6vw;
    width: 9.45vw;
    height: 2.80vw;
    background: #CC0711;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 0.8vw;
}
`, "",{"version":3,"sources":["webpack://./src/views/notFound/notFound.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;IACtB,+BAA+B;AACnC;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".containerNotFound {\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    background-color: #0F1841;\n}\n\n.containerContentNotFound {\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.titleNotFound {\n    margin: 0;\n    font-weight: 700;\n    font-size: 2.4vw;\n    text-align: center;\n    letter-spacing: 0.25px;\n    color: rgba(255, 255, 255, 0.9);\n}\n\n.subtitleNotFound {\n    margin: 1.5vw 0 2.1vw 0;\n    font-weight: 600;\n    font-size: 0.89vw;\n    text-align: center;\n    letter-spacing: 0.75px;\n    color: rgba(255, 255, 255, 0.75);\n}\n\n.buttonNotFound {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 0px 1.6vw;\n    width: 9.45vw;\n    height: 2.80vw;\n    background: #CC0711;\n    border-radius: 8px;\n    border: none;\n    color: #FFFFFF;\n    font-weight: 700;\n    font-size: 0.8vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
