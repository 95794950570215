// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.MenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: #0F1841;
    margin-top: 17px;
    cursor: pointer;
}

.MenuItemNoFocus {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #F2F4F6;
    background-color: #FFFFFF;
    cursor: pointer;
}

.iconMenuItem {
    width: 1.4vw;
    margin-left: 18px;
}

.textMenuItemFocus {
    text-align: left;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    font-size: 0.95vw;
    letter-spacing: 0.75px;
    color: #FFFFFF;
}

.textMenuItemNoFocus {
    text-align: left;
    margin-right: auto;
    margin-left: 20px;
    font-weight: 600;
    font-size: 0.95vw;
    letter-spacing: 0.75px;
    color: #526581;
}
`, "",{"version":3,"sources":["webpack://./src/components/menu/menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,gCAAgC;IAChC,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".containerMenu {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    margin-top: 50px;\n}\n\n.MenuItem {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    border-radius: 8px;\n    background-color: #0F1841;\n    margin-top: 17px;\n    cursor: pointer;\n}\n\n.MenuItemNoFocus {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    border-bottom: 1px solid #F2F4F6;\n    background-color: #FFFFFF;\n    cursor: pointer;\n}\n\n.iconMenuItem {\n    width: 1.4vw;\n    margin-left: 18px;\n}\n\n.textMenuItemFocus {\n    text-align: left;\n    margin-right: auto;\n    margin-left: 20px;\n    font-weight: 600;\n    font-size: 0.95vw;\n    letter-spacing: 0.75px;\n    color: #FFFFFF;\n}\n\n.textMenuItemNoFocus {\n    text-align: left;\n    margin-right: auto;\n    margin-left: 20px;\n    font-weight: 600;\n    font-size: 0.95vw;\n    letter-spacing: 0.75px;\n    color: #526581;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
