// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.containerNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: 100vh;
  box-shadow: 7px 0 5px -2px rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
}

.containerRouter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  text-align: center;
}

.containerRouterNoNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite .5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,aAAa;EACb,6CAA6C;EAC7C,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAiCG","sourcesContent":[".App {\n  overflow: hidden;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  width: 100%;\n  height: 100vh;\n  text-align: center;\n}\n\n.containerNav {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 20%;\n  height: 100vh;\n  box-shadow: 7px 0 5px -2px rgba(0, 0, 0, 0.2);\n  background-color: #FFFFFF;\n}\n\n.containerRouter {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 80%;\n  text-align: center;\n}\n\n.containerRouterNoNav {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  text-align: center;\n}\n\n/* .App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite .5s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
