import React, {useState, useEffect} from 'react'

import './users.css';

import {useNavigate} from 'react-router-dom';
import { Modal, List } from 'antd';

import Searcher from '../../components/searcher/searcher.component';
import HistoryItem from '../../components/historyItem/historyItem.component';
import ChatItem from '../../components/chatItem/chatItem.component';

import { getAllUsers, initCoachConversation } from '../../services/chat.service';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [coach, setCoach] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [openModal, setOpenModal] = useState(false)
    const [confirmLoading, setConfirmloading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        //Recogemos los datos del coach con el que tenemos la sesion
        let currentUser = localStorage.getItem('USER')
        console.log('currr', currentUser)
        setCoach(JSON.parse(currentUser))

        //Recogemos todos los usuarios
        getAllUsers().then(async res => {
            console.log('todos los usuarios', res);
            setUsers(res.data);
            setAllUsers(res.data);

            //Recogemos los datos del coach con el que tenemos la sesion
            let currentUser = await localStorage.getItem('USER')
            console.log('currr', currentUser)
            setCoach(JSON.parse(currentUser))
        }).catch(err => {
            console.log('ERROR al recoger los usuarios', err);
        })
    }, [])

    const handleOpenModal = (user) => {
        setSelectedUser(user)
        setOpenModal(true)
    }

    const handleConfirm = () => {
        console.log('coachid', coach)
        setConfirmloading(true)
        initCoachConversation(selectedUser.id, coach.user.id).then(res => {
            console.log('chat iniciado correctamente', res.data)
            setConfirmloading(false)
            setOpenModal(false)
            //Se envian estos parametros a chat directamente para abrir la conversación que acabamos de crear
            navigate('/chat', {state: {chatId: res.data.id, userId: res.data.current_user_id}})
        }).catch(err => {
            console.log('ERROR iniciando el chat', err)
        })
        setTimeout(() => {
        }, 3000)
    }

    const handleCancel = () => {
        setOpenModal(false)
    }

    const searchChats = (value) => {
        if (value !== '') {
            let newUsers = users.filter(user => {
                if (user.name.toLowerCase().includes(value.toLowerCase())) return user;
            })
            setUsers(newUsers);
        }
        else {
            setUsers(allUsers);
        }
    }

    return (
        <div className={'containerHistory'}>
            <Modal
                title="Abrir conversación"
                open={openModal}
                onOk={handleConfirm}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={'Aceptar'}
                cancelText={'Cancelar'}
            >
                <p>¿Estás seguro que quieres abrir conversación con {selectedUser && selectedUser.name} {selectedUser && selectedUser.lastname}?</p>
            </Modal>
            <p className='titleHistory'>Usuarios</p>

            <Searcher changeValue={value => searchChats(value)}/>

            {users &&
                console.log('user', users[76])
            }

            <div className={'containerItemsHistory'}>
            {users &&
                <List
                    style={{zIndex: 9}}
                    itemLayout={'vertical'}
                    size={'large'}
                    pagination={{
                        pageSize: 7,
                    }}
                    dataSource={users}
                    renderItem={(item) => (
                        <ChatItem
                            image={item.badge}
                            fullName={`${item.name} ${item.lastname}`}
                            organization={item.Organizations.length > 0 && item.Organizations[0].name}
                            press={() => {
                                handleOpenModal(item)
                                //navigate('/user', {state: {idUser: user.id}})
                            }}
                        />
                    )}
            />}
            </div>
        </div>
    )
}

export default Users;