// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1vw;
}

.textRadioButton {
    margin: 0 0 0 0.5vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/radioButton/radioButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 1vw;\n}\n\n.textRadioButton {\n    margin: 0 0 0 0.5vw;\n    font-weight: 400;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
