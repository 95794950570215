// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerSessions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.titleSessions {
    margin-right: 2vw;
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerColumns {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100% !important;
}

.titleColumn {
    margin-right: 2vw;
    font-weight: 700;
    font-size: 1.1vw;
    letter-spacing: 1px;
    color: #000000;

}`, "",{"version":3,"sources":["webpack://./src/views/sessions/sessions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;;AAElB","sourcesContent":[".containerSessions {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 90%;\n    height: 100%;\n    max-height: 100%;\n}\n\n.titleSessions {\n    margin-right: 2vw;\n    font-weight: 700;\n    font-size: 1.3vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.containerColumns {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.column{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    min-width: 100% !important;\n}\n\n.titleColumn {\n    margin-right: 2vw;\n    font-weight: 700;\n    font-size: 1.1vw;\n    letter-spacing: 1px;\n    color: #000000;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
