import React, {useState, useEffect} from 'react';

import './infoUserCard.css';
import {IconAssets, ImageAssets} from '../../utils/ImageAssets';

import moment from 'moment';

// Muestra el componente de sale al clicar dentro del nombre o imagen del usuario dentro de la conversacion
// En el que se muestran los datos relevantes del usuario
const InfoUserCard = ({user, close}) => {
    const [coachingPreferences, setCoachingPreferences] = useState()

    useEffect(() => {
        let newCoachingPreferences = user.channelUsers.user.coaching_preferences.split('#')
        setCoachingPreferences(newCoachingPreferences)
        console.log('coaching p', newCoachingPreferences)
    }, [])

    return (
        user &&
        <div className={'containerUserCard'}>
            <img className={'iconCloseUserCard'} src={IconAssets.close} onClick={() => close(false)}/>

            <div className={'containerImageUserCard'}>
                <img className={'imageUserCard'} src={user.channelUsers.user.image === null ? ImageAssets.userTest : (user.channelUsers.user.image.includes('https') ? user.channelUsers.user.image : `https://node.mybeatcoach.com/${user.channelUsers.user.image}`)}/>
            </div>

            {/*<p className={'nameUserCard'}>{user.channelUsers.user.name + '' + user.channelUsers.user.lastname}</p>*/}
            <p className={'titleUserCard'}>Empresa</p>
            <p className={'textDetailsUserCard'}>{(user.channelUsers.user.Organizations && user.channelUsers.user.Organizations.length > 0) ? user.channelUsers.user.Organizations[0].name : 'Sin departamento'}</p>
            {/*<p className={'companyUserCard'}>Empresa {(user.channelUsers.user.Organizations && user.channelUsers.user.Organizations.length > 0) ? user.channelUsers.user.Organizations[0].name : 'Desconocida'}</p>*/}

            {/*<p className={'titleUserCard'}>Departamento asignado</p>
            <p className={'textDetailsUserCard'}>{(user.channelUsers.user.Departments && user.channelUsers.user.Departments.length > 0) ? user.channelUsers.user.Departments[0].name : 'Sin departamento'}</p>*/}
            
            {coachingPreferences != null &&
                <>
                    <p className={'titleUserCard'}>Preferencias de coaching</p>
                    {coachingPreferences.map(res => {
                        return (
                            <p className={'textDetailsCoachingPreferences'}>{res}</p>
                        )
                    })

                    }
                </>
            }

            {/*<p className={'titleUserCard'}>Email</p>
            <p className={'textDetailsUserCard'}>{user.channelUsers.user.email}</p>

            <p className={'titleUserCard'}>Sexo</p>
            <p className={'textDetailsUserCard'}>{user.channelUsers.user.gender}</p>

            <p className={'titleUserCard'}>Fecha de nacimiento</p>
            <p className={'textDetailsUserCard'}>{moment(user.channelUsers.user.birthday).format('DD/MM/yyyy')}</p>*/}
        </div>
    )
}

export default InfoUserCard;
