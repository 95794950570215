// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerStateUser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    cursor: pointer;
}

.textStateUser {
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
}

.stateCircleConnect {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background-color: #16C83D;
}

.containerSelectStateUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.dotGreen {
    width: 0.75vw;
    height: 0.75vw;
    margin: 0 0.75vw;
    border-radius: 100px;
    background-color: #16C83D;
}

.dotRed {
    width: 0.75vw;
    height: 0.75vw;
    margin: 0 0.75vw;
    border-radius: 100px;
    background-color: #CC0711;
}

.dotOrange {
    width: 0.75vw;
    height: 0.75vw;
    margin: 0 0.75vw;
    border-radius: 100px;
    background-color: #ffbf47;
}

.selectStateUser {
    width: 9vw;
    height: 2.5vw;
    border: none;
    border-radius: 10px;
    background: #FFFFFF;
    outline: none;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/stateUser/stateUser.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB","sourcesContent":[".containerStateUser {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    margin-top: 40px;\n    cursor: pointer;\n}\n\n.textStateUser {\n    font-weight: 600;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n}\n\n.stateCircleConnect {\n    width: 15px;\n    height: 15px;\n    border-radius: 100px;\n    background-color: #16C83D;\n}\n\n.containerSelectStateUser {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    border: 1px solid rgba(0, 0, 0, 0.15);\n    border-radius: 10px;\n}\n\n.dotGreen {\n    width: 0.75vw;\n    height: 0.75vw;\n    margin: 0 0.75vw;\n    border-radius: 100px;\n    background-color: #16C83D;\n}\n\n.dotRed {\n    width: 0.75vw;\n    height: 0.75vw;\n    margin: 0 0.75vw;\n    border-radius: 100px;\n    background-color: #CC0711;\n}\n\n.dotOrange {\n    width: 0.75vw;\n    height: 0.75vw;\n    margin: 0 0.75vw;\n    border-radius: 100px;\n    background-color: #ffbf47;\n}\n\n.selectStateUser {\n    width: 9vw;\n    height: 2.5vw;\n    border: none;\n    border-radius: 10px;\n    background: #FFFFFF;\n    outline: none;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
