// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerSession {
    display: flex;
    flex-direction: row;
    width: 95%;
    padding: 15px 30px;
    margin-bottom: 15px;
    border-radius: 30px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.containerTextSession{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.nameSession {
    font-weight: 700;
    font-size: 1.10vw;
    letter-spacing: 1px;
    color: #000000;
    margin-top: 0.594vw;
    margin-bottom: 0.4vw;
}

.dateSession {
    text-align: left;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/components/session/sessionComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".containerSession {\n    display: flex;\n    flex-direction: row;\n    width: 95%;\n    padding: 15px 30px;\n    margin-bottom: 15px;\n    border-radius: 30px;\n    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);\n}\n\n.containerTextSession{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    margin-left: 10px;\n}\n\n.nameSession {\n    font-weight: 700;\n    font-size: 1.10vw;\n    letter-spacing: 1px;\n    color: #000000;\n    margin-top: 0.594vw;\n    margin-bottom: 0.4vw;\n}\n\n.dateSession {\n    text-align: left;\n    font-weight: 400;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
