// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerReportUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.titleReportUser {
    font-weight: 700;
    font-size: 1.75vw;
    letter-spacing: 1px;
    color: #0F1841;
}

.textReportUser {
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.textareaReportUser {
    width: 50vw;
    height: 12.9vw;
    margin-top: 2.7vw;
    padding-top: 1.40vw;
    padding-left: 1.15vw;
    border: 0.5px solid #969696;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    resize: none;
}

.textareaReportUser::placeholder {
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #969696;
}

.containerButtonsReportUser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 34.8vw;
    margin-top: 4.1vw;
}

.containerButton {
    width: 16vw;
}

`, "",{"version":3,"sources":["webpack://./src/views/reportUser/reportUser.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;IACpB,2BAA2B;IAC3B,mBAAmB;IACnB,4CAA4C;IAC5C,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".containerReportUser {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.titleReportUser {\n    font-weight: 700;\n    font-size: 1.75vw;\n    letter-spacing: 1px;\n    color: #0F1841;\n}\n\n.textReportUser {\n    font-weight: 400;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n}\n\n.textareaReportUser {\n    width: 50vw;\n    height: 12.9vw;\n    margin-top: 2.7vw;\n    padding-top: 1.40vw;\n    padding-left: 1.15vw;\n    border: 0.5px solid #969696;\n    border-radius: 20px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);\n    resize: none;\n}\n\n.textareaReportUser::placeholder {\n    font-weight: 400;\n    font-size: 0.89vw;\n    letter-spacing: 0.75px;\n    color: #969696;\n}\n\n.containerButtonsReportUser {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 34.8vw;\n    margin-top: 4.1vw;\n}\n\n.containerButton {\n    width: 16vw;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
