// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputVerify {
    width: 37px;
    height: 44px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    outline: none;
    background: #FFFFFF;
    text-align: center;
    -webkit-appearance: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/inputVerify/inputVerify.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,4EAA4E;IAC5E,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,wBAAwB;IACxB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX","sourcesContent":[".inputVerify {\n    width: 37px;\n    height: 44px;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 5px;\n    border: none;\n    outline: none;\n    background: #FFFFFF;\n    text-align: center;\n    -webkit-appearance: none;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 32px;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
