// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerMenuChat {
    position: absolute;
    width: 80%;
    height: 100%;
    background-color: transparent;
}

.menuChat {
    position: absolute;
    top: 4.2vw;
    right: 2.3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 17vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
}

.itemMenuChat {
    width: 100%;
    cursor: pointer;
}

.textItemMenu {
    margin: 1.1vw 2.3vw;
    font-weight: 400;
    font-size: 0.88vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.border {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.halfBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/menuChat/menuChat.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,4CAA4C;IAC5C,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,wCAAwC;IACxC,2CAA2C;AAC/C;;AAEA;IACI,2CAA2C;AAC/C","sourcesContent":[".containerMenuChat {\n    position: absolute;\n    width: 80%;\n    height: 100%;\n    background-color: transparent;\n}\n\n.menuChat {\n    position: absolute;\n    top: 4.2vw;\n    right: 2.3vw;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 17vw;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);\n    background-color: #FFFFFF;\n}\n\n.itemMenuChat {\n    width: 100%;\n    cursor: pointer;\n}\n\n.textItemMenu {\n    margin: 1.1vw 2.3vw;\n    font-weight: 400;\n    font-size: 0.88vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n}\n\n.border {\n    border-top: 1px solid rgba(0, 0, 0, 0.1);\n    border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.halfBorder {\n    border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
