// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerHistoryItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 0.594vw;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background: #FFFFFF;
    cursor: pointer;
}

.containerImageHistoryItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 4.75vw;
    height: 4.75vw;
    margin: 0.95vw 0.70vw 0.95vw 1.55vw;
    border-radius: 100px;
    overflow: hidden;
}

.imageHistoryItem {
    width: 115%;
    height: 115%;
    object-fit: contain;
}

.containerTextHistoryItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1;
}

.nameHistoryItem {
    margin: 0;
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

.companyHistoryItem {
    margin-top: 0.594vw;
    margin-bottom: 0.4vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.dateHistoryItem {
    margin-right: 1.45vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #969696;
}
`, "",{"version":3,"sources":["webpack://./src/components/historyItem/historyItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,2CAA2C;IAC3C,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,mCAAmC;IACnC,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,SAAO;AACX;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".containerHistoryItem {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    margin-top: 0.594vw;\n    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 20px;\n    background: #FFFFFF;\n    cursor: pointer;\n}\n\n.containerImageHistoryItem {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: 4.75vw;\n    height: 4.75vw;\n    margin: 0.95vw 0.70vw 0.95vw 1.55vw;\n    border-radius: 100px;\n    overflow: hidden;\n}\n\n.imageHistoryItem {\n    width: 115%;\n    height: 115%;\n    object-fit: contain;\n}\n\n.containerTextHistoryItem {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    flex: 1;\n}\n\n.nameHistoryItem {\n    margin: 0;\n    font-weight: 700;\n    font-size: 1.25vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.companyHistoryItem {\n    margin-top: 0.594vw;\n    margin-bottom: 0.4vw;\n    font-weight: 400;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n}\n\n.dateHistoryItem {\n    margin-right: 1.45vw;\n    font-weight: 400;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #969696;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
