// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerRating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.titleRating {
    font-weight: 700;
    font-size: 1.75vw;
    letter-spacing: 1px;
    color: #000000;
}

.rate {
    color: #0F1841;
    font-size: 2.3vw;
    margin-top: 1.45vw;
    margin-bottom: 5.5vw;
}

.textConfirmRating {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

/*.selectRating {
    width: 31vw;
    height: 3.75vw;
    margin-top: 2.6vw;
    border: 0.2px solid #595959;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
    outline: none;
}*/

.containerRadioButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.blockRadioButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blockRadioButtonsRight {
    margin-left: 5vw;
}

.containerButtonRating {
    width: 16vw;
    margin-top: 4.25vw;
}
`, "",{"version":3,"sources":["webpack://./src/views/rating/rating.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;;;;;;;;;;;;;EAaE;;AAEF;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".containerRating {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n}\n\n.titleRating {\n    font-weight: 700;\n    font-size: 1.75vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.rate {\n    color: #0F1841;\n    font-size: 2.3vw;\n    margin-top: 1.45vw;\n    margin-bottom: 5.5vw;\n}\n\n.textConfirmRating {\n    font-weight: 700;\n    font-size: 1.25vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n/*.selectRating {\n    width: 31vw;\n    height: 3.75vw;\n    margin-top: 2.6vw;\n    border: 0.2px solid #595959;\n    border-radius: 20px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);\n    background: #FFFFFF;\n    font-weight: 400;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n    outline: none;\n}*/\n\n.containerRadioButtons {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n}\n\n.blockRadioButtons {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.blockRadioButtonsRight {\n    margin-left: 5vw;\n}\n\n.containerButtonRating {\n    width: 16vw;\n    margin-top: 4.25vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
