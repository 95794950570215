import React, {useEffect, useState, useRef} from 'react';
import io from 'socket.io-client';

import './navBar.css';
import { ImageAssets } from '../../utils/ImageAssets';

import {useLocation} from 'react-router-dom';

import UserNavBar from '../userNavBar/userNavBar.component';
import StateUser from '../stateUser/stateUser.component';
import Menu from '../menu/menu.component';
import CloseSesion from '../closeSesion/closeSesion.component';
import {getConversations} from "../../services/chat.service";
import {getProfile, changeStatus} from "../../services/user.service";

// Muestra el componente del navbar
const NavBar = () => {
    const [user, setUser] = useState(null);
    const [imageUser, setImageUser] = useState(null);
    const [status, setStatus] = useState('No disponible');
    const [unreadMessages, setUnreadMessages] = useState(0)

    const location = useLocation();

    const socketRef = useRef(null);

    // Se ejecuta al cerrar la vista para poner el estado del coach offline
    // window.addEventListener('beforeunload', () => {
    //     let x = 1000;
    //     let a = (new Date()).getTime() + x;

    //     modifyStatus(null)
    //     while ((new Date()).getTime() < a) {}
    //   }, false);

    useEffect(() => {
        //modifyStatus('Disponible')

        // Recoge el usuario
        getUserProfile();

        // Recoge el usuario del localstorage
        let userStorage = localStorage.getItem('USER');
        setUser(JSON.parse(userStorage));


        //CONECTA EL SOCKET
        let token =localStorage.getItem('token');
        console.log('ENTER IN SOCKET CONNECT', token)
        socketRef.current = io('https://node.mybeatcoach.com/nsp-io-event', {query: `token=${token}`});
        socketEvents(JSON.parse(userStorage))
    }, [])

    const socketEvents = (user) => {
        //ENVIAMOS EL CHANNELID A NULL PARA HACER EL JOIN SOBRE TODAS LAS CONVERSACIONES NO SOBRE UNA CONCRETA
        socketRef.current.emit('join', {
            userId: user.user.id,
        });

        socketRef?.current?.on('connect', () => {
            console.log('CONECT TO SOCKET NAVBAR', socketRef.current.id);
        });

        socketRef?.current?.on('unread-messages', (e) => {
            console.log('LOS MENSAJES SIN LEER NAVBAR', e);
            setUnreadMessages(e.hasOwnProperty('unreadMessages') ? e.unreadMessages : e.unreadTotal)
        })

        socketRef?.current?.on('welcome', () => {
            console.log('WELCOME TO SOCKET NAVBAR');
        });

        socketRef?.current?.on('error', () => {
            console.log('CONECT SOCKET ERROR NAVBAR');
        });

        socketRef?.current?.on('disconnect', () => {
            console.log('CONECT SOCKET DISCONNECT NAVBAR');
            //removePushNotifications();
        });
    }

    // Recoge el usuario y comprueba el estado del coach para mostrar el puntito al lado de la foto
    const getUserProfile = () => {
        getProfile().then(user => {
            console.log('Perfil recogido con exito', user.data);
            setImageUser(user.data.image);
            if (user.data.status === 'Online' || user.data.status === 'Online') {
                setStatus('Disponible');

            } else if (user.data.status === 'connect')  {
                setStatus('Disponible');
            } else if (user.data.status === 'Offline' || user.data.status === 'offline') {
                setStatus('No disponible');
            } else {
                setStatus('Busy');
            }
        }).catch(err => {
            console.log('ERROR al recoger el perfil', err);
        })
    }

    // Modifica el estado del coach
    const modifyStatus = (status) => {
        let state = ''
        //let state = status === 'Disponible' ? 'Online' : 'Offline'
        switch(status) {
            case 'Disponible':
                state = 'Online';
            break;
            case 'Ausente':
                state = 'Busy';
            break;
            case 'No disponible':
                state = 'Offline'
            break;
        }
        console.log('STATUS', status)

        // Envia al servido rel nuevo estado del coach
        console.log('state', state)
        changeStatus(state).then(res => {
            // Recoge los datos de usuario
            getUserProfile();
            console.log('Estado actualizado con exito', res.data);
        }).catch(err => {
            console.log('ERROR al actualizar e estado', err);
        })
    }

    return (
        user !== null &&
        <div className={'containerNavBar'}>
            <img className={'logoNavBar'} src={ImageAssets.originalLogo}/>


            <div className={'containerItemsNavBar'}>
                <UserNavBar
                    name={`${user.user.name} ${user.user.lastname}`}
                    email={user.user.email}
                    image={imageUser}
                    status={status}
                />

                <StateUser value={status} changeValue={value => modifyStatus(value)}/>

                <Menu route={location.pathname} unreadMessages={unreadMessages}/>
            </div>

            <CloseSesion/>
        </div>
    )
}

export default NavBar;
