// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerSearcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 72vw;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    background: #F3F3F4;
}

.inputSearcher {
    flex: 1 1;
    height: 3.59vw;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #595959;
    background-color: transparent;
}

.inputSearcher::placeholder {
    font-weight: 600;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #595959;
}

.iconSearchSearcher {
    width: 1.63vw;
    height: 1.63vw;
    margin-right: 0.89vw;
    margin-left: 1.65vw;
}

.iconDownSearcher {
    width: 1vw;
    height: 0.6vw;
    margin-right: 1vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/searcher/searcher.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,4CAA4C;IAC5C,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,cAAc;IACd,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;IACd,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".containerSearcher {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 72vw;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n    border-radius: 50px;\n    background: #F3F3F4;\n}\n\n.inputSearcher {\n    flex: 1;\n    height: 3.59vw;\n    border: none;\n    outline: none;\n    font-weight: 600;\n    font-size: 0.89vw;\n    letter-spacing: 0.75px;\n    color: #595959;\n    background-color: transparent;\n}\n\n.inputSearcher::placeholder {\n    font-weight: 600;\n    font-size: 0.89vw;\n    letter-spacing: 0.75px;\n    color: #595959;\n}\n\n.iconSearchSearcher {\n    width: 1.63vw;\n    height: 1.63vw;\n    margin-right: 0.89vw;\n    margin-left: 1.65vw;\n}\n\n.iconDownSearcher {\n    width: 1vw;\n    height: 0.6vw;\n    margin-right: 1vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
