// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerUserNavBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-top: 40px;
}

.imageNavBar {
    width: 5vw;
    height: 5vw;
    border-radius: 100px;
}

.containerTextUserNavBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 8px;
}
.nameUserNavBar {
    margin: 8px 0 0 0;
    font-weight: 700;
    font-size: 1.5vw;
    letter-spacing: 0.75px;
    color: #595959;
}

.emailUserNavBar {
    text-overflow: ellipsis;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #595959;
    overflow: hidden;
    max-width: 13vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/userNavBar/userNavBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".containerUserNavBar {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 90%;\n    margin-top: 40px;\n}\n\n.imageNavBar {\n    width: 5vw;\n    height: 5vw;\n    border-radius: 100px;\n}\n\n.containerTextUserNavBar {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n    margin-left: 8px;\n}\n.nameUserNavBar {\n    margin: 8px 0 0 0;\n    font-weight: 700;\n    font-size: 1.5vw;\n    letter-spacing: 0.75px;\n    color: #595959;\n}\n\n.emailUserNavBar {\n    text-overflow: ellipsis;\n    margin-bottom: 0;\n    font-weight: 600;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #595959;\n    overflow: hidden;\n    max-width: 13vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
