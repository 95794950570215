// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerArticles {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100vh;
    min-height: 12.5vw;
    max-height: 100%;
}

.titleArticles {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.descriptionArticles {
    margin-top: 0.5vw;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #030303;
}

.selectArticles {
    width: 11.25vw;
    height: 2.5vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    color: #000000;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    outline: none;
}

.containerItemArticles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/views/articles/articles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,4CAA4C;IAC5C,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".containerArticles {\n    overflow: scroll;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 90%;\n    height: 100vh;\n    min-height: 12.5vw;\n    max-height: 100%;\n}\n\n.titleArticles {\n    font-weight: 700;\n    font-size: 1.3vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.descriptionArticles {\n    margin-top: 0.5vw;\n    font-weight: 400;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #030303;\n}\n\n.selectArticles {\n    width: 11.25vw;\n    height: 2.5vw;\n    font-weight: 400;\n    font-size: 0.89vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n    border: none;\n    border-radius: 10px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);\n    outline: none;\n}\n\n.containerItemArticles {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
