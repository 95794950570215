import React, {useEffect, useState, useRef} from 'react';
import InfoUserCard from "../../components/infoUserCard/infoUserCard.component";
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import MenuChat from "../../components/menuChat/menuChat.component";
import {IconAssets, ImageAssets} from "../../utils/ImageAssets";
import ChatItem from "../../components/chatItem/chatItem.component";
import Message from "../../components/message/message.component";
import * as moment from 'moment';

import './chatHistory.css';
import {getConversation, reopenConversation} from "../../services/chat.service";
import { Crypt, RSA } from 'hybrid-crypto-js';

const ChatHistory = () => {
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const inputRef = useRef(null);

    const [showUserCard, setShowUserCard] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [chats, setChats] = useState(null);
    const [message, setMessage] = useState('');
    const [lastMessage, setLastMessage] = useState(null);
    const [idChat, setIdChat] = useState(null);
    const [idSession, setIdSession] = useState(null);

    const [messages, setMessages] = useState([]);
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [actualConversation, setActualConversation] = useState();
    const [fileSended, setFileSended] = useState(false);

    const [conversationsOpened, setConversationsOpened] = useState([]);

    const [channelPrevId, setChannelPrevId] = useState(null);
    const [channelNextId, setChannelNextId] = useState(location.state.channelId);

    useEffect(() => {
        setIdChat(location.state.channelId);

        //RECOGE LA CONVERSACION A LA QUE HAS ENTRADO
        getConversation(location.state.channelId).then(conversation => {
            /*console.log('Conversaciones recogidas con exito', conversations.data)
            setChannelNextId(location.state.channelId);
            setConversationsOpened(opened => [...opened, location.state.channelId]);
            let conversat = conversations.data.filter(conver => conver.id === Number(location.state.channelId));
            setActualConversation(conversat[0]);
            setIdSession(conversat[0].videocall_uuid)
            setMessages(conversat[0].Messages);
            setChats(conversations.data.reverse());*/
            setMessages(conversation.data[0].Messages.reverse());
            setActualConversation(conversation.data[0]);
        }).catch(err => {
            console.log('ERROR al recoger las conversaciones', err);
        })
    }, []);

    // Desencripta un mensaje
    const decriptMessage = (message) => {
        let privateKey = localStorage.getItem('privatekey');
        console.log('PRIVATEKEY', typeof privateKey)
        console.log('MESSAGE', typeof message)
        let crypt = new Crypt();
        try {
            let messageDecripted = crypt.decrypt(privateKey, message);
            return messageDecripted.message;
        } catch (err) {
            console.log('catch decript')
        }
    }

    const reopenChat = () => {
        reopenConversation(idChat).then(res => {
            console.log('Chat reopened correctly', res.data)

            navigate('/chat');
        }).catch(err => {
            console.log('ERROR reopen chat', err)
        })
    }

    return (
        <div className={'containerBlocksChat'}>

            {showUserCard &&
                <InfoUserCard
                    user={actualConversation}
                    close={value => setShowUserCard(value)}
                />
            }

            {showMenu &&
                <MenuChat
                    inputRef={inputRef}
                    reopenChat={reopenChat}
                    type="history"
                    pressNoMenu={() => setShowMenu(false)}
                    idChannel={idChat}
                />
            }

            {/*<div className={'blockChatsChat'}>
                <div className={'headerChat'}>
                    <img className={'iconBackChat'} src={IconAssets.back} onClick={() => {
                        navigate('/history')
                    }}/>
                </div>
                {chats !== null &&
                <div className={'containerChatsChat'}>
                    {chats.map((chat, index) => {
                        return (
                            <ChatItem
                                image={chat.UserChannels[0].User.image}
                                fullName={`${chat.UserChannels[0].User.name} ${chat.UserChannels[0].User.lastname}`}
                                lastMessage={chat.Messages.length > 0 && decriptMessage(chat.Messages[0].content)}
                                size={'little'}
                                organization={(chat.UserChannels[0].User.Organizations && chat.UserChannels[0].User.Organizations.length > 0) ? chat.UserChannels[0].User.Organizations[0].name : 'Desconocida'}
                                unreadMessages={idChat === chat.id ? 0 : chat.unreadMessages.length}
                                timeLastMessage={chat.ended_at ? chat.ended_at : ''}
                                press={() => {
                                    setMessages(chat.Messages);
                                    setIdChat(chat.id)
                                }}
                            />
                        )
                    })
                    }
                </div>
                }
            </div>*/}

            <div className={'containerChatChat'}>
                {actualConversation &&
                <div className={'headerChat'}>
                    <img className={'iconBackChat'} src={IconAssets.back} onClick={() => {
                        navigate('/history')
                    }}/>
                    <div className={'containerImageUserChat'}>
                        <img className={'imageUserChat'} src={actualConversation.UserChannels[0].User.image === null ? ImageAssets.userTest : (actualConversation.UserChannels[0].User.image.includes('https') ? actualConversation.UserChannels[0].User.image : `https://node.mybeatcoach.com/${actualConversation.UserChannels[0].User.image}`)} onClick={() => {
                            setShowUserCard(!showUserCard);
                            setShowMenu(false);
                        }}/>
                    </div>
                    <p className={'nameUserChat'}  onClick={() => {
                        setShowUserCard(!showUserCard);
                        setShowMenu(false);
                    }}>{`${actualConversation.UserChannels[0].User.name} ${actualConversation.UserChannels[0].User.lastname}`}</p>
                    <img className={'iconOptionsChat'} src={IconAssets.options} onClick={() => {
                        setShowMenu(!showMenu);
                        setShowUserCard(false);
                    }}/>
                </div>
                }
                <div className={'containerChat'}>
                    {typeData === null ?
                        <div id={'containerMessages'} className={'containerMessagesChat'} onLoad={() => console.log('BOTTTTTOMMMM')}>
                            {messages.length > 0 &&
                            (messages.map(message => {
                                return (
                                    <Message
                                        sender={message.user_id === actualConversation.User.id}
                                        message={message.content}
                                        idChat={message.channel_id}
                                        type={message.message_type}
                                        time={moment(message.createdAt).format('HH:mm')}
                                        file={message.file}
                                        pressFile={value => setFilePreview(value)}
                                        typeFile={value => setTypeData(value)}
                                        isSended={value => setFileSended(value)}
                                        press={() => {
                                            let token =localStorage.getItem('token');
                                            console.log("UUID videocall: ", )
                                            window.open(`https://mbcwebchat.innobing.net/sfu/${idSession}/${token}`);
                                        }}
                                    />
                                )
                            })
                        )
                            }
                        </div>
                        :
                        <div className={'containerFilesChat'}>
                            {typeData === 'image' &&
                            <>
                                <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                    setTypeData(null);
                                    setFile(null);
                                    setFilePreview(null);
                                    setFileSended(false);
                                }}/>
                                <img className={'imageFile'} src={filePreview}/>
                            </>
                            }
                            {typeData === 'video' &&
                            <>
                                <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                    setTypeData(null);
                                    setFile(null);
                                    setFilePreview(null);
                                    setFileSended(false);
                                }}/>
                                <video style={{width: '100%', height: '90%'}} src={filePreview} controls/>
                            </>
                            }
                            {typeData === 'document' &&
                            <>
                                <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                    setTypeData(null);
                                    setFile(null);
                                    setFilePreview(null);
                                    setFileSended(false);
                                }}/>
                                <iframe src={filePreview+'#toolbar=0'} width={'100%'} height={'90%'}/>
                            </>
                            }
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default ChatHistory;
