// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerSpeciality {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2vw;
    margin-right: 1.25vw;
    margin-bottom: 10px;
    padding: 0 0.65vw;
    border-radius: 20px;
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #595959;
    background: #F4F4F4;
}
`, "",{"version":3,"sources":["webpack://./src/components/speciality/speciality.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,oBAAoB;IACpB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".containerSpeciality {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    height: 2vw;\n    margin-right: 1.25vw;\n    margin-bottom: 10px;\n    padding: 0 0.65vw;\n    border-radius: 20px;\n    font-weight: 400;\n    font-size: 0.75vw;\n    letter-spacing: 0.75px;\n    color: #595959;\n    background: #F4F4F4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
