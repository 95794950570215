// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerDotQuantityLittleFocus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 100px;
    background-color: #FFFFFF;
    color: #CC0711;
    font-weight: 400;
    font-size: 0.8vw;
}

.containerDotQuantityLittleNoFocus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 100px;
    background-color: #CC0711;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8vw;
}

.containerDotQuantityBig {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.75vw;
    height: 1.75vw;
    border-radius: 100px;
    background-color: #0F1841;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/dotQuantity/dotQuantity.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,oBAAoB;IACpB,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".containerDotQuantityLittleFocus {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: 1.5vw;\n    height: 1.5vw;\n    border-radius: 100px;\n    background-color: #FFFFFF;\n    color: #CC0711;\n    font-weight: 400;\n    font-size: 0.8vw;\n}\n\n.containerDotQuantityLittleNoFocus {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: 1.5vw;\n    height: 1.5vw;\n    border-radius: 100px;\n    background-color: #CC0711;\n    color: #FFFFFF;\n    font-weight: 400;\n    font-size: 0.8vw;\n}\n\n.containerDotQuantityBig {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: 1.75vw;\n    height: 1.75vw;\n    border-radius: 100px;\n    background-color: #0F1841;\n    color: #FFFFFF;\n    font-weight: 400;\n    font-size: 0.8vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
