import React, {useEffect, useState} from 'react';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import AuthRouter from './router/authRouter';
import Router from './router/router';
import { isLogged } from './services/auth.service';
import {updateOneSignalId} from './services/user.service';
import loginStore from './store/loginStore';
import routeStore from './store/routeStore';

import NavBar from './components/navBar/navBar.component';

import OneSignal from 'react-onesignal';


function App() {

  const [checkingLogin, setCheckingLogin] = useState(true);
  const [socketRef, setSocketRef] = useState(true);
  const login = loginStore(state => state.login);
  const setLogin = loginStore(state => state.setLogin);
  const route = routeStore(state => state.route);

  useEffect(() => {
    console.log('the bell', document.getElementById('onesignal-bell-container'));
    console.log('ROUTE APPP', route);
    console.log('languaje', navigator.languages)
  }, [route])

  OneSignal.on('notificationDisplay', function(event) {
    console.log('notificationDisplay', event)
    const notification = event;
    const options = {
      body: notification.content,
      icon: notification.icon,
      // Otras opciones de personalización de la notificación
    };
    new Notification(notification.heading, options);
  });

  useEffect(() => {
    if (login) {
      console.log('Enter useeffect oneSignal')

      /*OneSignal.getNotificationPermission().then(async res => {
        console.log('permissons onesignal', res)
        await OneSignal.getUserId( async (userId) => {
          console.log('player_id of the subscribed user is : ' + userId);
          // Make a POST call to your server with the user ID
        });
      });*/

      OneSignal.init({
        appId: "6df19771-e803-4a9a-bf1b-c0e507832158",
        notifyButton: {
          enable: true,
          position: 'bottom-left',
          offset: {
            bottom: '120px',
            left: '20px'
          }
        },
        subdomainName: "https://workplace.mybeatcoach.com",
      }).then(async res => {
        console.log('then init oneSignal')
        OneSignal.getNotificationPermission().then(async res => {
          console.log('permissons onesignal', res)
          await OneSignal.getUserId( async (userId) => {
            console.log('player_id of the subscribed user is : ' + userId);
            updateOneSignalId(userId).then(res => {
              console.log('oneSiganalId saved correctly', res.data);
            }).catch(err => {
              console.log('error oneSiganalId saved correctly', err);
            })
            // Make a POST call to your server with the user ID
          });
        });
        await OneSignal.registerForPushNotifications().then(async () => {
          console.log('then registeronesignal')
          await OneSignal.isPushNotificationsEnabled(async (isEnabled) => {
            console.log('isEnabled onesignal', isEnabled)
            if (!isEnabled) {
              // user has subscribed
              await OneSignal.getUserId( async (userId) => {
                console.log('player_id of the subscribed user is : ' + userId);
                // Make a POST call to your server with the user ID
              });
            }
          });
        }).catch(err => {
          console.log('error registeronesignal')
        });
      }).catch(err => {
        console.log('Error to crate onesignal')
      });
    }
  }, [login])

  useEffect(() => {
    isLogged().then(res => {
      console.log('res', res);
      setLogin(true);
    }).catch(err => {
      setLogin(false);
    }).finally(() => setCheckingLogin(false));
  }, [])

  return (
        <div className="App">
          <BrowserRouter>
            {(login && route !== '/rating') &&
            <div className={'containerNav'}>
              <NavBar/>
            </div>
            }

            <div className={(login && route !== '/rating') ? 'containerRouter' : 'containerRouterNoNav'}>
              {login ?
                  <Router socket={value => setSocketRef(value)}/>
                  :
                  <AuthRouter/>
              }
            </div>
          </BrowserRouter>
        </div>
  );
}

export default App;
