// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerArticle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.containerGoBackArticle {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.iconGoBackArticle {
    width: 0.5vw;
    height: 0.85vw;
}

.goBackArticle {
    margin-left: 0.6vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    text-decoration-line: underline;
    color: #0F1841;
}

.containerTitleArticle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.titleArticle {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerButtonArticle {
    width: 12vw;
}

.descriptionArticle {
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.pdfArticle {
    margin-top: 2.05vw;
}
`, "",{"version":3,"sources":["webpack://./src/views/article/article.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,+BAA+B;IAC/B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".containerArticle {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 90%;\n    height: 100%;\n    max-height: 100%;\n}\n\n.containerGoBackArticle {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    cursor: pointer;\n}\n\n.iconGoBackArticle {\n    width: 0.5vw;\n    height: 0.85vw;\n}\n\n.goBackArticle {\n    margin-left: 0.6vw;\n    font-weight: 400;\n    font-size: 0.89vw;\n    letter-spacing: 0.75px;\n    text-decoration-line: underline;\n    color: #0F1841;\n}\n\n.containerTitleArticle {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.titleArticle {\n    font-weight: 700;\n    font-size: 1.25vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.containerButtonArticle {\n    width: 12vw;\n}\n\n.descriptionArticle {\n    font-weight: 400;\n    font-size: 0.75vw;\n    letter-spacing: 0.75px;\n    color: #000000;\n}\n\n.pdfArticle {\n    margin-top: 2.05vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
