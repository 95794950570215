import axios from 'axios';

const axiosInstance = axios.create({baseURL: 'https://node.mybeatcoach.com/api/', responseType: 'json'});


/**
 * Recoge los articulos
 * */
export const getArticles = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('post_categories', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las especialidades
 * */
export const getSpecialities = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('specialities', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las sesiones
*/
export const getSessions = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('getAllSessionsNotCompleted', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Actualiza la sessio añadiendo los datos del coach
 */
export const updateSession = async (coachId, coachName, coachImage, sessionId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        coach_id: coachId,
        coach_name: coachName,
        coach_image: coachImage,
        session_id: sessionId
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('updateSessionWithCoach', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las sesiones cerradas del coach
 */
export const getCloseSessionsCoach = async (coachId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        coach_id: coachId,
    }

    return new Promise((resolve, reject) => {
        axiosInstance.post('getCoachSessionsClose', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}