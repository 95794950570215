// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerWriter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.inputWriter {
    flex: 1 1;
    height: 3.5vw;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0.75vw;
    border: none;
    outline: none;
    border-radius: 0.2vw;
    background-color: #FFFFFF;
    resize: none;
}

.senderWriter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.05vw;
    height: 3.5vw;
    margin-left: 0.44vw;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/writer/writer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,SAAO;IACP,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;IACpB,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".containerWriter {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    border-radius: 10px;\n    background-color: #FFFFFF;\n}\n\n.inputWriter {\n    flex: 1;\n    height: 3.5vw;\n    padding-top: 5px;\n    padding-bottom: 5px;\n    padding-left: 0.75vw;\n    border: none;\n    outline: none;\n    border-radius: 0.2vw;\n    background-color: #FFFFFF;\n    resize: none;\n}\n\n.senderWriter {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: 3.05vw;\n    height: 3.5vw;\n    margin-left: 0.44vw;\n    border-radius: 4px;\n    background-color: #FFFFFF;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
