// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerCloseSesion {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100px;
    margin-top: auto;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #FFFFFF;
    cursor: pointer;
}

.iconCloseSesion {
    width: 1.05vw;
    height: 1.09vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/closeSesion/closeSesion.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".containerCloseSesion {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    width: 100%;\n    height: 100px;\n    margin-top: auto;\n    background-color: #0F1841;\n    font-weight: 600;\n    font-size: 1vw;\n    letter-spacing: 0.75px;\n    color: #FFFFFF;\n    cursor: pointer;\n}\n\n.iconCloseSesion {\n    width: 1.05vw;\n    height: 1.09vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
