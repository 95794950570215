// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerMessages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.titleMessages {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerChatsMessages {
    overflow: scroll;
    width: 100%;
    max-height: 700px;
    min-height: 105px;
    margin-top: 3vw;
}
`, "",{"version":3,"sources":["webpack://./src/views/messages/messages.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".containerMessages {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 90%;\n    height: 100%;\n    max-height: 100%;\n}\n\n.titleMessages {\n    font-weight: 700;\n    font-size: 1.3vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.containerChatsMessages {\n    overflow: scroll;\n    width: 100%;\n    max-height: 700px;\n    min-height: 105px;\n    margin-top: 3vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
